import * as React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import Box from '@material-ui/core/Box';
import { Link, Typography } from 'Atoms';
import { PRIVACY_POLICY_URL } from 'Client/constants/urls';
import { useProject } from 'Client/utils/hooks';

/** @deprecated on CF 3.0 */
export const PrivacyPolicyNotice: React.FC = () => {
  const { t } = useTranslation();
  const project = useProject();
  const subject = t('Question about {{projectName}}', {
    projectName: project.name,
  });
  const emailLink = `mailto:support@commonplace.is?subject=${subject}`;

  return (
    <Box mt={0.3}>
      <Trans>
        <Typography data-testid="privacy-policy-text">
          {'Read '}
          <Link
            to={PRIVACY_POLICY_URL}
            noPadding
            color="CommonplaceBrand"
            newTab
            external
          >
            {"Commonplace's privacy policy"}
          </Link>
          {' or get in touch with any '}
          <Link to={emailLink} noPadding color="CommonplaceBrand" external>
            {'questions'}
          </Link>
          {'.'}
        </Typography>
      </Trans>
    </Box>
  );
};
